<template>
  <div class="loufengExperReort">
    <van-sticky>
      <div class="header">
        <van-icon class="back" name="arrow-left" size="25" color="white" @click="$router.go(-1)" />
        <span class="title">体验报告</span>
      </div>
    </van-sticky>
    <div class="main">
      <div class="serverDetail">
        <div class="title">
          服务详情
        </div>
        <van-field
          class="content"
          v-model="content"
          rows="4"
          autosize
          size="large"
          type="textarea"
          placeholder="可详细描述mm个人情况，MM质量，服务过程（尤其是妹妹的特色）等，描述越详细，用户信任感使得。。（不限字数）"
        />
      </div>
      <div class="upload">
        <div class="title">
          上传照片
        </div>
        <div>
          <van-uploader v-model="fileList" upload-icon="plus" :after-read="upload" @delete="removeImg" />
        </div>
      </div>
      <div class="require">
        <div class="require-title"></div>
        <div class="require-content">
          <p>1.必须保证真实有效，请谨慎对待；</p>
          <p>2.照片必须包含支付凭证截图；</p>
          <p>3.建议聊天截图，妹子照片，环境照片等；</p>
          <p>4.至少4张照片；</p>
          <p>5.不满意以上条件不予审核通过。</p>
        </div>
      </div>
      <div class="bottom">
        <div class="subBtn" @click="subExperReport">
          确认提交
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
import { uploadImg, addExperReport } from '@/api/loufeng.js';

export default {
  name: 'loufengExperReort',
  data() {
    return {
      content: '',
      fileList: [],
      uploadImg: [],
      imgs: [],
      loufengId: 0 // 楼凤Id
    }
  },
  created() {
    this.getLoufengId();
  },
  methods: {
    getLoufengId() {
      if(localStorage.getItem('loufengId')) {
        this.loufengId = localStorage.getItem('loufengId');
      } else {
        this.loufengId = '';
      }
    },
    // 上传照片
    async upload(fileInfo) {
      let req = new FormData();
      req.append('file', fileInfo.file);
      fileInfo.status = 'uploading';
      fileInfo.message = '上传中...';
      let res = await this.$Api(uploadImg, req);
      if(res && res.code === 200) {
        fileInfo.status = '';
        fileInfo.message = '';
        let tempImg = {
          name: fileInfo.file.name,
          url: res.data.path
        }
        this.uploadImg.push(tempImg);
        this.$toast('照片上传成功');
      } else {
        this.$toast('照片上传异常');
      }
    },
    // 移除照片
    removeImg(file) {
      let tempArr = this.uploadImg.filter(item => {
        if(!(item.name === file.file.name)) {
          return item
        }
      })
      this.uploadImg = tempArr;
    },
    // 提交表单
    async subExperReport() {
      this.imgs = this.uploadImg.map(item => item.url);
      let req = {
        content: this.content,
        girlId: parseInt(this.loufengId),
        girlType: 'loufeng',
        imgs: this.imgs,
        orderNo: ""
      }
      let res = await this.$Api(addExperReport, req);
      if(res && res.code === 200) {
        this.$toast('提交成功');
        this.$router.go(-1);
      } else {
        this.$toast(res.tip ? res.tip : res.msg);
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .loufengExperReort{
    .header {
      height: 44px;
      width: 100%;
      background-color: rgb(242,19,19);
      color: white;
      font-size: 20px;
      text-align: center;
      line-height: 44px;
      .back {
        position: absolute;
        left: 14px;
        top: 10px;
      }
    }
    .main{
      padding: 0px 15px;
      .title{
        font-size: 17px;
        height: 47px;
        line-height: 47px;
      }
      .serverDetail{
        height: 170px;
        .content{
          box-shadow: 0px 0px 3px 1px rgba(112,112,112, 0.2);
          border-radius: 10px;
        }
      }
      .upload{
        /deep/ .van-cell {
          padding: unset;
        }
      }
      .require{
        .require-title{
          height: 70px;
          background: url('../../assets/png/exper-report.png') center center;
          background-repeat: no-repeat;
          background-size: 170px 30px;
        }
        .require-content{
          font-size: 14px;
          p{
            line-height: 25px;
          }
        }
      }
      .bottom {
        height: 40px;
        margin-top: 40px;
        .subBtn {
          width: 100%;
          height: 40px;
          font-size: 22px;
          color: white;
          text-align: center;
          line-height: 40px;
          background-color: red;
          border-radius: 10px;
        }
      }
    }
  }
  .all-content {
    overflow: auto;
  }
</style>